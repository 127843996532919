@use "~@otto-ec/global-frontend-settings/gfs" as *;
@use "~@otto-ec/global-frontend-settings/all" as legacy;

html {
  height: 100%;
  overflow-y: scroll; /*                                 */
  background: $pl_color-grey25;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: $pl_color-white100;
  color: $pl_color-black100;
  height: 100%;
  margin: 0;
  -webkit-text-size-adjust: 100%; /*                               */
  @include breakpoint-raw(legacy.$min-show-info-container-width) {
    background: linear-gradient(180deg, #fff 0%, #fff 166px, transparent 166px, transparent 100%)
      no-repeat;

    .app & {
      background: $pl_color-white100;
    }
  }

  &.popupPage {
    background: $pl_color-white100;
    height: auto;
  }
}

body,
button,
input,
select,
textarea {
  font-family: legacy.$sansFontFamily;
}

p {
  margin-bottom: $pl_margin50;
}

h1 {
  font-family: "OttoSansThin", "OTTOSans", Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: normal;
  line-height: 30px;
}

h2 {
  font-family: "OttoSans", Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 26px;
}

h3 {
  font-family: "OttoSans", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
}

h4 {
  font-family: "OttoSans", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
}

h5 {
  font-family: "OttoSans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

h6 {
  font-family: "OttoSans", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
}

input[type="password"] {
  font-family: legacy.$fallbackFontFamily;
}

/*                                          */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

a {
  *color: $pl_color-black100;
  color: inherit;
}

/*           */
.ub64e {
  cursor: pointer;
  text-decoration: underline;
}

strong,
.bold {
  font-weight: bold;
}

.hide {
  display: none;
}

.iconfont {
  font-family: "OttoIconFonts", Arial, Helvetica, sans-serif;
}

.line {
  border-bottom: 1px solid #d6d6d6;
  clear: both;
  display: block;
  height: 1px;
  margin: 0;
  width: 100%;
}

.logo {
  background: url("../img/otto_logo.png") no-repeat;
  cursor: pointer;
  display: block;
  height: 55px;
  margin: 27px 0 18px;
  text-decoration: none; /*                                  */
}

footer .ofig {
  text-align: center;
}

.footerSmall .push {
  height: 0;
}

/*                                                                                                  */
@media print {
  body {
    background-color: $pl_color-white100;
    color: $pl_color-black100;
  }

  body,
  button,
  input,
  select,
  textarea {
    font-family: Arial, Helvetica, sans-serif;
  }

  .product-system .header-info,
  .product-system .omega,
  .product-system nav,
  .product-system footer .bigLinkTable tr th,
  .product-system footer .bigLinkTable tr td,
  .product-system footer .iconRow,
  .user_feedback_splice_bar_live {
    display: none;
  }

  .product-system footer .bigLinkTable tr th.width3,
  .product-system footer .bigLinkTable tr td.width3 {
    display: table-cell;
    *display: inline;
  }
}
