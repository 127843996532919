/*                                   */
$breakpoint-no-query-fallbacks: true;

/*          */
$susy: (
    columns: 6,
    container: 1152px,
    gutter-position: inside,
    gutters: 0,
);

/*                  */
$medium-width: 28em;

/*                 */
$medium-cols: 6;

/*                       */
$breakpoint-medium: $medium-width, $medium-cols;

/*                 */
$large-width: 48em;

/*                */
$large-cols: 12;

/*                      */
$breakpoint-large: $large-width, $large-cols;

/*                       */
$extra-large-width: 62em;

/*                      */
$extra-large-cols: 12;

/*                            */
$breakpoint-extra-large: $extra-large-width, $extra-large-cols;

/*                                                                                                                           */
/*                        */
$inner-grid-padding: 8px;

/*                                                                 */
/*                        */
$outer-grid-padding: 8px;

/*                                       */
/*                                   */
$min-show-info-container-width: 77em;

/*                                  */
$min-show-info-container-cols: 12;

/*                                        */
$breakpoint-min-show-info-container: $min-show-info-container-width, $min-show-info-container-cols;

/*                                   */
$max-show-info-container-width: 85em;

/*                                  */
$max-show-info-container-cols: 12;

/*                                        */
$breakpoint-max-show-info-container: $max-show-info-container-width, $max-show-info-container-cols;
