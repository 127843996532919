@use "sass:math";
@use "~@otto-ec/global-frontend-settings/gfs" as *;
@use "~@otto-ec/global-frontend-settings/all" as legacy;
@import "~@otto-ec/global-frontend-settings/scss/grid";
@import "~@otto-ec/global-frontend-settings/scss/variables";
/*                       */

/*                          */
$info-container-width: 160px;

/*                                 */
$info-container-margin-right: 6px;

/*                                */
$info-container-margin-left: 24px;

/*                                */
$info-container-total-width: $info-container-width + $info-container-margin-right +
  $info-container-margin-left;

/*                               */
.wrapper {
  background: $white;
  height: auto !important;
  margin: 0 auto -193px;
  min-height: 100%;
  padding: 0 45px;
  position: relative;
  zoom: 1;
  text-align: left;
}

.content_and_av .wrapper {
  float: left;
}

.content {
  display: block;
  margin-bottom: 10px;
  min-height: 400px;
  z-index: 1;
}

.content::after {
  clear: both;
  content: ".";
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}

.content_and_av {
  height: 100%;
  margin: 0 auto;
  width: 1263px;
}

@media screen and (max-width: 1259px) {
  .content_and_av {
    height: 100%;
    width: auto;
  }
}

@media print {
  .wrapper {
    box-shadow: none;
  }

  .content_and_av {
    width: auto;
  }
}

/*                           */
.gridContainer {
  max-width: map-get($susy, container);
  min-width: 320px;
  padding: 0 8px;

  &:after {
    clear: both;
    content: " ";
    display: block;
  }

  &.reducedOuterPadding {
    padding: 0;
  }

  @include from-l() {
    padding: 0 16px;

    &.reducedOuterPadding {
      padding: 0 $outer-grid-padding;
    }
  }

  @include breakpoint-raw(legacy.$min-show-info-container-width) {
    padding: 0 32px;

    &.reducedOuterPadding {
      padding: 0 32px;
    }
  }
}

.gridAndInfoContainer {
  height: 100%;

  @include breakpoint-raw(legacy.$max-show-info-container-width) {
    margin-left: auto;
    margin-right: auto;
    width: map-get($susy, container) + $info-container-total-width;
  }

  .gridContainer {
    @include breakpoint-raw(legacy.$min-show-info-container-width) {
      $unitless-info-container-total-width: math.div($info-container-total-width, 1px);
      $unitless-min-show-info-container-width: math.div($min-show-info-container-width, 1em) * 16;
      $content-share-of-total-width: 1 -
        math.div($unitless-info-container-total-width, $unitless-min-show-info-container-width);
      float: left;
      width: floor(percentage($content-share-of-total-width));
      min-width: 1025px; /*                                                                            */

      .app & {
        float: none;
      }
    }

    @include breakpoint-raw(legacy.$max-show-info-container-width) {
      float: left;
      width: map-get($susy, container);
    }
  }

  .infoContainer {
    display: none;

    @include breakpoint-raw(legacy.$min-show-info-container-width) {
      display: inline-block;
      margin: 214px $info-container-margin-right 0 $info-container-margin-left;
      width: $info-container-width;

      .app & {
        display: none;
      }
    }
  }
}
