/**
 *
 *
 *
 *
 *
 */

.center {
  text-align: center;
}

/**
 */

/*                                              */

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

/*                                                                                     */

.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
}

/**
 *
 *
 */

.clearfix {
  zoom: 1;
}
