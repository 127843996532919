/**
 *
 *
 *
 */

$pl_border-radius: (
"10": $pl_border-radius10,
"25": $pl_border-radius25,
"50": $pl_border-radius50,
"100": $pl_border-radius100,
);
$pl_color: (
"success": $pl_color-success,
"sustainable": $pl_color-sustainable,
"hint": $pl_color-hint,
"warm-red": $pl_color-warm-red,
"deep-navy": $pl_color-deep-navy,
"red100": $pl_color-red100,
"blue100": $pl_color-blue100,
"white100": $pl_color-white100,
"black100": $pl_color-black100,
"grey25": $pl_color-grey25,
"grey100": $pl_color-grey100,
"grey200": $pl_color-grey200,
"grey400": $pl_color-grey400,
);
$pl_margin: (
"25": $pl_margin25,
"50": $pl_margin50,
"75": $pl_margin75,
"100": $pl_margin100,
"150": $pl_margin150,
"175": $pl_margin175,
"200": $pl_margin200,
"250": $pl_margin250,
"300": $pl_margin300,
);
$pl_padding: (
"25": $pl_padding25,
"50": $pl_padding50,
"75": $pl_padding75,
"100": $pl_padding100,
"150": $pl_padding150,
"175": $pl_padding175,
"200": $pl_padding200,
"250": $pl_padding250,
"300": $pl_padding300,
);

$pl_font-sizes: (
"display100": $pl_display100,
"display200": $pl_display200,
"headline10": $pl_headline10,
"headline25": $pl_headline25,
"headline50": $pl_headline50,
"headline100": $pl_headline100,
"headline200": $pl_headline200,
"headline300": $pl_headline300,
"copy50": $pl_copy50,
"copy75": $pl_copy75,
"copy100": $pl_copy100,
"copy125": $pl_copy125,
);