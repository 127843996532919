@use "~@otto-ec/global-frontend-settings/gfs";
@use "~@otto-ec/global-frontend-settings/all" as legacy;
@import "~@otto-ec/global-frontend-settings/scss/pl_variables";
@import "~@otto-ec/global-frontend-settings/scss/pl_maps";
$pl_prefix: pl_;

@each $name, $value in $pl_padding {
  .#{$pl_prefix}p#{$name} {
    padding: $value;
  }

  .#{$pl_prefix}pl#{$name} {
    padding-left: $value;
  }

  .#{$pl_prefix}pr#{$name} {
    padding-right: $value;
  }

  .#{$pl_prefix}pt#{$name} {
    padding-top: $value;
  }

  .#{$pl_prefix}pb#{$name} {
    padding-bottom: $value;
  }

  .#{$pl_prefix}px#{$name} {
    padding-left: $value;
    padding-right: $value;
  }

  .#{$pl_prefix}py#{$name} {
    padding-bottom: $value;
    padding-top: $value;
  }
}

@each $name, $value in $pl_margin {
  .#{$pl_prefix}m#{$name} {
    margin: $value;
  }

  .#{$pl_prefix}ml#{$name} {
    margin-left: $value;
  }

  .#{$pl_prefix}mr#{$name} {
    margin-right: $value;
  }

  .#{$pl_prefix}mt#{$name} {
    margin-top: $value;
  }

  .#{$pl_prefix}mb#{$name} {
    margin-bottom: $value;
  }

  .#{$pl_prefix}mx#{$name} {
    margin-left: $value;
    margin-right: $value;
  }

  .#{$pl_prefix}my#{$name} {
    margin-bottom: $value;
    margin-top: $value;
  }
}

@each $name, $value in $pl_border-radius {
  .#{$pl_prefix}radius#{$name} {
    border-radius: $value;
  }
}

@each $name, $prop in $pl_font-sizes {
  .#{$pl_prefix}#{$name} {
    @each $prop_key, $prop_val in $prop {
      #{$prop_key}: $prop_val;
    }
  }
}

@each $name, $color in $pl_color {
  .#{$pl_prefix}text-#{$name} {
    color: $color;
  }
  .#{$pl_prefix}bg-#{$name} {
    background-color: $color;
  }
}

/*                          */
@mixin setFullBleedMargins($padding) {
  margin-left: $padding;
  margin-right: $padding;

  &--right {
    margin-right: $padding;
  }

  &--left {
    margin-left: $padding;
  }
}

@mixin setFullBleedPadding($padding) {
  padding-left: $padding;
  padding-right: $padding;

  &--right {
    padding-right: $padding;
  }

  &--left {
    padding-left: $padding;
  }
}

.gridContainer {
  @include gfs.from-l {
    $breakpoint-large-margin: -16px;
    .pl_full-bleed {
      @include setFullBleedMargins($breakpoint-large-margin);
      &--with-padding {
        @include setFullBleedMargins($breakpoint-large-margin);
        @include setFullBleedPadding(calc(#{$breakpoint-large-margin} * -1));
      }
    }

    .contentWithSidebar .pl_full-bleed {
      margin-right: $breakpoint-large-margin;
      margin-left: -8px;
      &--with-padding {
        padding-right: calc(#{$breakpoint-large-margin} * -1);
        padding-left: 8px;
      }
    }

    /*                                                                                             */
    &.reducedOuterPadding .pl_full-bleed {
      @include setFullBleedMargins(-$outer-grid-padding);
      &--with-padding {
        @include setFullBleedMargins(-$outer-grid-padding);
        @include setFullBleedPadding($outer-grid-padding);
      }
    }

    /*                                                                                             */
    &.reducedOuterPadding .contentWithSidebar .pl_full-bleed {
      margin-right: -$outer-grid-padding;
      margin-left: -8px;
      &--with-padding {
        margin-right: -$outer-grid-padding;
        margin-left: -8px;
        padding-right: $outer-grid-padding;
        padding-left: 8px;
      }
    }
  }

  @include gfs.breakpoint-raw(legacy.$min-show-info-container-width) {
    .pl_full-bleed,
    &.reducedOuterPadding .pl_full-bleed {
      @include setFullBleedMargins(-32px);
      &--with-padding {
        @include setFullBleedMargins(-32px);
        @include setFullBleedPadding(32px);
      }
    }

    .contentWithSidebar .pl_full-bleed,
    &.reducedOuterPadding .contentWithSidebar .pl_full-bleed {
      margin-right: -32px;
      margin-left: -8px;
      &--with-padding {
        margin-right: -32px;
        margin-left: -8px;
        padding-right: 32px;
        padding-left: 8px;
      }
    }
  }

  /*                                                                                                             */
  @media (min-width: 1153px) and (max-width: 1231px) {
    $no-ad-banner-padding: calc(((100vw - 1152px) / -2));
    $no-ad-with-reduced-padding: calc(#{$no-ad-banner-padding} - #{$outer-grid-padding});
    .pl_full-bleed {
      @include setFullBleedMargins($no-ad-banner-padding);
      &--with-padding {
        @include setFullBleedMargins($no-ad-banner-padding);
        @include setFullBleedPadding(calc(#{$no-ad-banner-padding} * -1));
      }
    }

    &.reducedOuterPadding .pl_full-bleed {
      @include setFullBleedMargins($no-ad-with-reduced-padding);
      &--with-padding {
        @include setFullBleedMargins($no-ad-with-reduced-padding);
        @include setFullBleedPadding(calc(#{$no-ad-with-reduced-padding} * -1));
      }
    }
    .contentWithSidebar .pl_full-bleed {
      margin-right: $no-ad-banner-padding;
      margin-left: -8px;
      &--with-padding {
        margin-right: $no-ad-banner-padding;
        margin-left: -8px;
        padding-right: calc(#{$no-ad-banner-padding} * -1);
        padding-left: 8px;
      }
    }
    &.reducedOuterPadding .contentWithSidebar .pl_full-bleed {
      margin-right: $no-ad-with-reduced-padding;
      margin-left: -8px;
      &--with-padding {
        margin-right: $no-ad-with-reduced-padding;
        margin-left: -8px;
        padding-right: calc(#{$no-ad-with-reduced-padding} * -1);
        padding-left: 8px;
      }
    }
  }
}

/*                                                                               */
.mo-frame,
.gridContainer.mo-frame,
.reducedOuterPadding.mo-frame,
.wrapper.mo-frame {
  background: $pl_color-grey25;
  padding-left: 0;
  padding-right: 0;

  .pl_full-bleed {
    margin-left: 0;
    margin-right: 0;
    &--with-padding {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.gridContainer.mo-frame {
  @media (min-width: 1153px) and (max-width: 1231px) {
    max-width: 100%;
  }
}

/*        */
.pl_hidden {
  display: none !important;
}

.pl_shadow100 {
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, .05), 0 0 2px 0 rgba(0, 0, 0, .15);
}

.pl_shadow200 {
	box-shadow: 0 4px 12px 6px rgba(0, 0, 0, .1), 0 1px 3px 0 rgba(0, 0, 0, .1);
}

.pl_shadow300 {
	box-shadow: 0 10px 30px 12px rgba(0, 0, 0, .15);
}
